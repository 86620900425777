@keyframes placeholder-fade {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.8;
  }
}

@keyframes ph-animation {
  0% {
    transform: translate3d(-30%, 0, 0);
  }
  100% {
    transform: translate3d(30%, 0, 0);
  }
}

.placeholder {
  height: 450px;
  opacity: 1;
  animation: placeholder-fade 2s linear infinite;
  position: relative;
  overflow: hidden;
  direction: ltr;
  background: white;
}

.placeholder::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 50%;
  z-index: 1;
  width: 500%;
  margin-left: -250%;
  pointer-events: none;
  content: ' ';
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 46%,
      rgba(255, 255, 255, 0.05) 50%,
      rgba(255, 255, 255, 0) 54%
    )
    50% 50%;
  animation: ph-animation 2s linear infinite;
  mix-blend-mode: difference;
}

@import '@/styles/_variables';

.container {
  width: 100%;
  margin: 0 auto;
}

@media (min-width: $xxl) {
  .container {
    margin: 0 auto;
  }
}

.content {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.loader {
  margin: 150px auto;
  position: relative;
}
